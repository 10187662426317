import { useStaticQuery, graphql } from 'gatsby'

const useWorks = (workSearchList) => {
  const works = useStaticQuery(graphql`
    query WorkQueryByHook {
      allWork(sort: {fields: date, order: DESC}) {
        nodes {
          work_id
          work_name
          date
          image_portrait
          image_landscape
        }
      }
      allExternalWork(sort: {fields: date, order: DESC}) {
        nodes {
          work_name
          date
          image_portrait
          image_landscape
          project_link
        }
      }
    }
  `)

  const allWorks = [];
  const searchedWorks = [];
  works.allWork.nodes.forEach((work) => allWorks.push({type: 'work', ...work}))
  works.allExternalWork.nodes.forEach((work) => {
    if (work.work_name !== 'placeholder') {
      allWorks.push({type: 'external_work', ...work})
    }
  })
  workSearchList.forEach(search => {
    const index = allWorks.findIndex((item) => item.type === search.type && item.work_id === search.work)
    if (index > -1) {
      searchedWorks.push(allWorks[index])
      allWorks.splice(index, 1)
    }
  })
  const remainingWorks = allWorks.sort((firstEl, secondEl) => secondEl.date - firstEl.date)

  return [...searchedWorks, ...remainingWorks]
}

export default useWorks
