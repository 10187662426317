import React from 'react'
import CaseStudyPair from '../../work/CaseStudyPair'
import WorkSplitIllustration from '../../work/SplitIllustration'
import useWorks from '~hooks/useWorks'
import useMediaQuery from '~hooks/useMediaQuery'

import './index.scss'

const CaseStudies = ({ data, isPreview }) => {
  const studies = useWorks(data)
  const isTablet = useMediaQuery('(max-width: 1024px)')

  const tabletList = () => (
    studies.map((study) => (
      <CaseStudyPair data={{case_study_left: study}} key={study} isPreview={isPreview} />
    ))
  )

  const desktopList = () => {
    let count = 0
    let loopCount = 0
    let data = {}
    const caseStudyEls = []
    for (let i = 0; i < studies.length; i++) {
      if (count === 0) {
        if (data.hasOwnProperty('case_study_left')) {
          data.case_study_right = studies[i]
          caseStudyEls.push(<CaseStudyPair data={data} isPortrait={true} key={loopCount * 6 + count} isPreview={isPreview} />)
          count = count + 1
          data = {}
        } else {
          data.case_study_left = studies[i]
          if (!studies[i + 1]) {
            caseStudyEls.push(<CaseStudyPair data={data} isPortrait={true} key={loopCount * 6 + count} isPreview={isPreview} />)
            count = count + 1
            data = {}
          }
        }
      } else if (count === 1) {
        let data = {}
        data.case_study = studies[i]
        caseStudyEls.push(<WorkSplitIllustration data={data} key={loopCount * 6 + count} isPreview={isPreview} />)
        count = count + 1
        data = {}
      } else if (count === 2) {
        if (data.hasOwnProperty('case_study_left')) {
          data.case_study_right = studies[i]
          caseStudyEls.push(<CaseStudyPair data={data} key={loopCount * 6 + count} isPreview={isPreview} />)
          count = count + 1
          data = {}
        } else {
          data.case_study_left = studies[i]
          if (!studies[i + 1]) {
            caseStudyEls.push(<CaseStudyPair data={data} key={loopCount * 6 + count} isPreview={isPreview} />)
            count = count + 1
            data = {}
          }
        }
      } else if (count === 3) {
        data.case_study = studies[i]
        caseStudyEls.push(<WorkSplitIllustration data={data} isTextLeft={true} key={loopCount * 6 + count} isPreview={isPreview} />)
        count = 0
        loopCount = loopCount + 1
        data = {}
      }
    }
    return caseStudyEls
  }

  return (
    <section className="case-studies">
      <div className="case-studies__container">
        {isTablet && tabletList()}
        {!isTablet && desktopList()}
      </div>
    </section>
  )
}

export default CaseStudies
