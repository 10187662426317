import React from 'react'
import Link from '~components/Link'
import classnames from 'classnames'
import useIntersectionObserver from '~components/global/IntersectionObserver'

import Ruler from '~components/Ruler'

import './index.scss'

const CaseStudyPair = ({ data, isPortrait = false, isPreview }) => {
  const [componentRef] = useIntersectionObserver({
    root: null,
    rootMargin: "-25%",
    threshold: 0
  })

  const caseStudyPairClass = classnames(
    'case-study-pair',
    { 'case-study-pair--portrait': isPortrait }
  )

  const caseStudy = (caseStudy, side = 'left') => {
    const isExternal = caseStudy.type === 'external_work'
    return (
      <div className={`case-study-pair__case-study case-study-pair__case-study--${side}`}>
        {isExternal ?
          <>
            <a className="case-study-pair__case-study__image--wrapper"
              href={caseStudy.project_link} target="_blank" rel="noopener noreferrer">
              <img className="case-study-pair__case-study__image" alt={caseStudy.work_name}
                src={isPortrait ? caseStudy.image_portrait : caseStudy.image_landscape} />
            </a>
            <Ruler
              data={{
                leftText: {
                  text: caseStudy.work_name,
                  type: 'label',
                  color: 'black'
                },
                rightText: {
                  tag: 'a',
                  text: 'Visit ↗︎',
                  url: caseStudy.project_link
                }
              }}
              isPreview={isPreview}
            />
          </> :
          <>
            <Link linkClass="case-study-pair__case-study__image--wrapper"
              to={`/work/${caseStudy.work_id}`} isPreview={isPreview}>
              <img className="case-study-pair__case-study__image" alt={caseStudy.work_name}
                src={isPortrait ? caseStudy.image_portrait : caseStudy.image_landscape} />
            </Link>
            <Ruler
              data={{
                leftText: {
                  text: caseStudy.work_name,
                  type: 'label',
                  color: 'black'
                },
                rightText: {
                  tag: 'link',
                  text: 'Explore',
                  url: `/work/${caseStudy.work_id}`
                }
              }}
              isPreview={isPreview}
            />
          </>
        }
      </div>
    )
  }

  return (
    <section className={caseStudyPairClass} ref={componentRef}>
      <div className="case-study-pair__container">
        <div className="case-study-pair__case-studies">
          { data.case_study_left && caseStudy(data.case_study_left) }
          { data.case_study_right && caseStudy(data.case_study_right, 'right') }
        </div>
      </div>
    </section>
  )
}

export default CaseStudyPair
