import React from 'react'

import Layout from '~global/Layout'
import WorkPageWrap from '~pageWraps/WorkPageWrap'

import data from '../content/work.yaml'

const Work = () => {
  return (
    <Layout>
      <WorkPageWrap data={data}/>
    </Layout>
  )
}

export default Work
