import React from 'react'

import SplitIllustration from '~components/SplitIllustration'

import './index.scss'

const WorkSplitIllustration = ({ data, isTextLeft = false, isPreview }) => {
  const isExternal = data.case_study.type === 'external_work'
  const splitIllustrationData = {
    content: {
      title: data.case_study.work_name,
      description: data.case_study.subtitle,
      button: {
        tag: isExternal ? 'a' : 'link',
        text: isExternal ? 'Visit ↗︎' : 'Explore',
        url: isExternal ? data.case_study.project_link : `/work/${data.case_study.work_id}`
      }
    },
    image: data.case_study.image_landscape,
  }

  return (
    <section className="work-split-illustration">
      <div className="work-split-illustration__container">
        <SplitIllustration data={splitIllustrationData} isTextLeft={isTextLeft} isPreview={isPreview} />
      </div>
    </section>
  )
}

export default WorkSplitIllustration
